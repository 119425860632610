import IconAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import { Link } from 'gatsby';
import React from 'react';
import './Button.scss';

const Button = ({ styles, clickHandler, children, linkTo }) => {
  const className =
    'Button' +
    (styles?.reduce((prev, style) => prev + ` Button--${style}`, '') || '');
  const isPageLink = linkTo?.startsWith('#');
  const isHref = isPageLink || /^https?:\/\//.test(linkTo);
  const hasArrow = styles.some((style) => ['down', 'right'].includes(style));

  const content = (
    <>
      {children}
      {hasArrow && <IconAngleRight size={24} className={'Button__arrow'} />}
    </>
  );

  return isHref ? (
    <a
      href={linkTo}
      className={className}
      target={isPageLink ? '' : '_blank'}
      rel={isPageLink ? '' : 'noreferrer'}
    >
      {content}
    </a>
  ) : linkTo ? (
    <Link to={linkTo} className={className}>
      {content}
    </Link>
  ) : (
    <button onClick={clickHandler} className={className}>
      {content}
    </button>
  );
};

export default Button;
