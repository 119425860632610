import { Link, graphql } from 'gatsby';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from 'react-transition-group';
import Layout from '../components/Layout';
import Section from '../components/Section/Section';
import Seo from '../components/Seo';
import Button from '../components/Button/Button';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import { PodcastPlayer } from '../components/PodcastPlayer/PodcastPlayer';
import './Podcast.scss';

const PodcastIndex = ({ data, location }) => {
  const showStep = 3;
  const initialCount = 5;
  const [showCount, setShowCount] = useState(0);
  const today = moment().endOf('day');
  const episodes = data.episodes.nodes;
  const pastEpisodes = episodes.filter((ep) =>
    moment(ep.frontmatter.date).isSameOrBefore(today),
  );
  const nextEpisode = episodes
    .filter((ep) => moment(ep.frontmatter.date).isAfter(today))
    .pop();
  const pageData = data.page;
  const { seoTitle, description, title } = data.page.frontmatter;

  useEffect(() => {
    setShowCount(initialCount);
  }, []);

  const content =
    pastEpisodes.length === 0 ? (
      <p>Bisher keine Episoden erschienen.</p>
    ) : (
      <>
        {nextEpisode && (
          <>
            <h2 className="Section__title">Nächste Folge</h2>
            <ol className="Podcast__list">
              <li className="Podcast__item" style={{ opacity: 1 }}>
                <article itemScope itemType="http://schema.org/Article">
                  <time>Demnächst</time>
                  <div>
                    <h3>
                      <span itemProp="headline">
                        {nextEpisode.frontmatter.title ||
                          nextEpisode.fields.slug}
                      </span>
                    </h3>
                    <section
                      dangerouslySetInnerHTML={{
                        __html:
                          nextEpisode.frontmatter.description ||
                          nextEpisode.excerpt,
                      }}
                      itemProp="description"
                    />
                  </div>
                </article>
              </li>
            </ol>
          </>
        )}
        <h2 className="Section__title">Bisherige Folgen</h2>
        <ol className="Podcast__list">
          {pastEpisodes.map((episode, i) => {
            const title = episode.frontmatter.title || episode.fields.slug;

            return (
              <CSSTransition
                in={i < showCount}
                timeout={600}
                classNames="fade"
                key={episode.fields.slug}
              >
                <li
                  className={'Podcast__item'}
                  style={{
                    transitionDelay:
                      i < initialCount
                        ? i * 100 + 'ms'
                        : i + showStep >= showCount
                        ? (i + showStep - showCount) * 150 + 'ms'
                        : '300ms',
                  }}
                >
                  <AnimateHeight
                    duration={200}
                    height={i < initialCount || i < showCount ? 'auto' : 0}
                  >
                    <Link to={episode.fields.slug} itemProp="url">
                      <article itemScope itemType="http://schema.org/Article">
                        <time>{episode.frontmatter.formattedDate}</time>
                        <div>
                          <h3>
                            <span itemProp="headline">{title}</span>
                          </h3>
                          <section
                            dangerouslySetInnerHTML={{
                              __html:
                                episode.frontmatter.description ||
                                episode.excerpt,
                            }}
                            itemProp="description"
                          />
                        </div>
                      </article>
                    </Link>
                  </AnimateHeight>
                </li>
              </CSSTransition>
            );
          })}
        </ol>
        {showCount < pastEpisodes.length && (
          <p style={{ textAlign: 'center', marginTop: '3rem' }}>
            <Button
              styles={['turquoise', 'down']}
              clickHandler={() => {
                setShowCount(showCount + 3);
              }}
            >
              Mehr
            </Button>
          </p>
        )}
      </>
    );

  return (
    <Layout location={location}>
      <Seo
        title={seoTitle || title}
        description={description}
        location={location}
      />
      <ContentHeader title={title} />
      <Section>
        <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
        <PodcastPlayer redcircleID={'3b0ea0dc-def2-4732-88dc-70f7844caf54'} />
      </Section>
      <Section type="turquoise-light" id="list">
        {content}
      </Section>
    </Layout>
  );
};

export default PodcastIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/podcast/" } }) {
      frontmatter {
        title
        seoTitle
        description
      }
      html
    }
    episodes: allMarkdownRemark(
      filter: {
        fields: { slug: { glob: "/podcast/*" } }
        # frontmatter: { date: { gte: "2022-02-21" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          formattedDate: date(formatString: "D. MMMM YYYY", locale: "de-DE")
          date
          title
          templateKey
          description
        }
      }
    }
  }
`;
