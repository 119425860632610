import React, { useState } from 'react';
import Loader from '../Loader/Loader';
import './PodcastPlayer.scss';

// import ReactJkMusicPlayer from 'react-jinke-music-player';
// import 'react-jinke-music-player/assets/index.css';

export const PodcastPlayer = ({ redcircleID }) => {
  // const audioLists = [
  //   {
  //     name: title,
  //     cover:
  //       'https://media.redcircle.com/images/2022/2/2/13/cda43dd0-4001-425c-8b9f-6b57b5f61839_c9-465a-920b-f243c51a7fad_20211217_175515_0000.jpg?d=440x440',
  //     musicSrc: url,
  //   },
  // ];
  const [loaded, setLoaded] = useState(false);
  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <>
      {/*<section itemProp="articleBody">*/}
      {/*  <ReactJkMusicPlayer*/}
      {/*    audioLists={audioLists}*/}
      {/*    // remember={true}*/}
      {/*    autoPlay={false}*/}
      {/*    // locale={'de_DE'}*/}
      {/*    mode={'full'}*/}
      {/*    remove={false}*/}
      {/*    showPlayMode={false}*/}
      {/*    showThemeSwitch={false}*/}
      {/*    toggleMode={false}*/}
      {/*  />*/}
      {/*</section>*/}

      {redcircleID && (
        <section className={'PodcastPlayer__section'}>
          <div style={{ position: 'relative' }}>
            <div
              className={
                'PodcastPlayer__loader' +
                (loaded ? ' PodcastPlayer__loader--loaded' : '')
              }
            >
              <div>
                <Loader />
              </div>
              {/*Player wird geladen*/}
            </div>
            {/*<a href="https://podcasts.apple.com/us/podcast/stressbefreiung/id1608069884?itsct=podcast_box_promote_link&itscg=30200">*/}
            {/*  Foo*/}
            {/*</a>*/}

            {/*<section>*/}
            {/*  <iframe*/}
            {/*    // style="border-radius:12px"*/}
            {/*    src="https://open.spotify.com/embed/show/6ZpG4cu3FgRaIoMYJk6v3Q?utm_source=generator"*/}
            {/*    width="100%"*/}
            {/*    height="232"*/}
            {/*    frameBorder="0"*/}
            {/*    allowFullScreen=""*/}
            {/*    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"*/}
            {/*  />*/}
            {/*</section>*/}

            <iframe
              className={loaded ? 'rc-player rc-player--loaded' : 'rc-player'}
              title="RedCircle"
              style={{ border: 'none', minWidth: '100%' }}
              height="170"
              scrolling="no"
              onLoad={onLoad}
              src={`https://redcircle.com/embedded-player/sh/2a7ea6e2-18b7-45d2-b751-988272fa7458/ep/${redcircleID}`}
            />
          </div>
        </section>
      )}
    </>
  );
};
